import type { ActivitiesSearchCriteria } from '@esp/activities/data-access-api';
import type { ProjectStatus } from '@esp/projects/types';

const ACTION_SCOPE = '[ActivitiesSearch]';

export namespace ActivitiesSearchActions {
  export class Search {
    static type = `${ACTION_SCOPE} Search`;

    constructor(
      public projectId: number,
      public criteria: ActivitiesSearchCriteria,
      public projectStatuses?: ProjectStatus[]
    ) {}
  }

  export class SearchReset {
    static type = `${ACTION_SCOPE} Search reset`;

    constructor(
      public projectId: number,
      public projectStatuses?: ProjectStatus[]
    ) {}
  }

  export class LoadMore {
    static type = `${ACTION_SCOPE} Load More`;

    constructor(
      public projectId: number,
      public projectStatuses: ProjectStatus[],
      public activityGroupIndex: number
    ) {}
  }
}
