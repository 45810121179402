import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { combineLatest, EMPTY, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { syncLoadProgress, syncOperationProgress } from '@cosmos/state';
import type {
  ModelWithLoadingStatus,
  OperationStatus,
} from '@cosmos/types-common';
import { AutocompleteService } from '@esp/autocomplete/data-access-api';
import type {
  SimpleParty,
  UserTeam,
} from '@esp/autocomplete/types-autocomplete';
import { SearchCriteria } from '@esp/common/types';
import type { SearchStateModel } from '@esp/search/types-search';
import { TasksService } from '@esp/tasks/data-access/api';
import type { TaskSearch } from '@esp/tasks/types';

import { TasksActions } from '../actions';
import { TasksSearchActions } from '../actions/tasks-search.actions';
import { extractTaskAssigneesIds } from '../utils/task-assignees.util';
import { extractTaskCompaniesIds } from '../utils/task-companies.util';

export interface TasksSearchStateModel
  extends SearchStateModel<any>,
    ModelWithLoadingStatus {
  criteria: SearchCriteria;
  total: number;
  results: TaskSearch[];
  assignees: UserTeam[];
  companies: SimpleParty[];
  taskStatuses: string[];
  categories: string[];
  notesLoading: Record<number, OperationStatus | undefined>;
}

const defaultState = (): TasksSearchStateModel => ({
  results: [],
  criteria: new SearchCriteria(),
  total: 0,
  assignees: [],
  companies: [],
  taskStatuses: [],
  categories: [],
  notesLoading: {},
});

type LocalStateContext = StateContext<TasksSearchStateModel>;

@State<TasksSearchStateModel>({
  name: 'tasksSearch',
  defaults: defaultState(),
})
@Injectable()
export class TasksSearchState {
  constructor(
    private readonly _service: TasksService,
    private readonly _autocompleteService: AutocompleteService
  ) {}

  @Action(TasksSearchActions.Search)
  search(ctx: LocalStateContext, { criteria }: TasksSearchActions.Search) {
    ctx.patchState({ criteria });

    return this._service.query<TaskSearch>(criteria).pipe(
      switchMap((result) => {
        const assigneesIds = extractTaskAssigneesIds(
          result?.Aggregations?.Assignee
        );
        const companiesIds = extractTaskCompaniesIds(
          result.Aggregations?.Company
        );

        return combineLatest([
          assigneesIds.length
            ? this._autocompleteService.usersAndTeams({
                term: '',
                size: assigneesIds.length,
                filters: {
                  Ids: {
                    Terms: assigneesIds,
                    Behavior: 'Any',
                  },
                },
              })
            : of([]),
          companiesIds.length
            ? this._autocompleteService.parties({
                term: '',
                size: companiesIds.length,
                filters: {
                  Ids: {
                    Terms: companiesIds,
                    Behavior: 'Any',
                  },
                },
              })
            : of([]),
        ]).pipe(
          map(([assignees, companies]) => {
            return {
              result,
              assignees,
              companies,
            };
          })
        );
      }),

      tap((result) => {
        ctx.patchState({
          results: result.result.Results,
          assignees: result.assignees,
          companies: result.companies,
          taskStatuses: result.result?.Aggregations.Statuses,
          categories: result.result?.Aggregations.Category.filter(
            (category: string) => category
          ),
          total: result.result.ResultsTotal,
        });
      }),
      syncLoadProgress(ctx)
    );
  }

  @Action(TasksSearchActions.SearchWithExistingCriteria)
  private _searchWithExistingCriteria(ctx: LocalStateContext) {
    const state = ctx.getState();
    return this._service.query<TaskSearch>(state.criteria).pipe(
      syncLoadProgress(ctx),
      switchMap((result) => {
        const assigneesIds = extractTaskAssigneesIds(
          result?.Aggregations?.Assignee
        );
        const companiesIds = extractTaskCompaniesIds(
          result.Aggregations?.Company
        );

        return combineLatest([
          assigneesIds.length
            ? this._autocompleteService.usersAndTeams({
                term: '',
                size: assigneesIds.length,
                filters: {
                  Ids: {
                    Terms: assigneesIds,
                    Behavior: 'Any',
                  },
                },
              })
            : of([]),
          companiesIds.length
            ? this._autocompleteService.parties({
                term: '',
                size: companiesIds.length,
                filters: {
                  Ids: {
                    Terms: companiesIds,
                    Behavior: 'Any',
                  },
                },
              })
            : of([]),
        ]).pipe(
          map(([assignees, companies]) => {
            return {
              result,
              assignees,
              companies,
            };
          })
        );
      }),
      tap((result) => {
        ctx.patchState({
          results: result.result.Results ?? undefined,
          assignees: result.assignees,
          companies: result.companies,
          taskStatuses: result.result?.Aggregations.Statuses,
          categories: result.result?.Aggregations.Category,
          total: result.result.ResultsTotal ?? undefined,
        });
      })
    );
  }

  @Action(TasksActions.Refresh)
  private _refreshState(ctx: LocalStateContext) {
    const state = ctx.getState();
    if (
      state?.criteria?.filters &&
      state?.criteria?.filters['Project'] &&
      state?.criteria?.filters['Project']?.terms?.length > 0
    ) {
      return ctx.dispatch(new TasksSearchActions.SearchWithExistingCriteria());
    } else {
      return EMPTY;
    }
  }

  @Action(TasksSearchActions.LoadTaskNotes, { cancelUncompleted: true })
  loadTaskNotes(
    ctx: LocalStateContext,
    { taskId }: TasksSearchActions.LoadTaskNotes
  ) {
    return this._service.getTaskNotes(taskId).pipe(
      tap((notes) => {
        ctx.setState(
          patch({
            results: updateItem(
              (task) => task.Id === taskId,
              patch({
                Notes: notes,
              })
            ),
          })
        );
      }),
      syncOperationProgress(ctx, (statusUpdateStateOperator) => {
        return patch<TasksSearchStateModel>({
          notesLoading: patch({
            [taskId]: statusUpdateStateOperator,
          }),
        });
      })
    );
  }
}
