import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { append, insertItem } from '@ngxs/store/operators';
import {
  catchError,
  combineLatest,
  EMPTY,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';

import {
  setEntityStateItem,
  syncLoadProgress,
  type EntityStateModel,
} from '@cosmos/state';
import type { ModelWithLoadingStatus } from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import {
  ActivitiesApiService,
  ActivitiesSearchCriteria,
} from '@esp/activities/data-access-api';
import type { Activity } from '@esp/activities/types';
import { AutocompleteService } from '@esp/autocomplete/data-access-api';
import type { SearchResult } from '@esp/common/types';

import { ActivitiesActions, ActivitiesSearchActions } from '../actions';
import type { ActivityAggregations } from '../models';
import { getActivityPeriods } from '../utils';
import {
  getCompaniesIds,
  getListAutocompleteParams,
  mapCompaniesToSimpleParties,
  mapPeopleToSimpleParties,
} from '../utils/aggregations.util';

import { TOAST_MESSAGES } from './toast-messages';

interface ActivityStatusGroup {
  currentPage: number;
  activities: Activity[];
  total: number;
}

export interface ActivitiesSearchStateModel
  extends ModelWithLoadingStatus,
    EntityStateModel<ActivityStatusGroup> {
  facets: ActivityAggregations;
  criteria?: ActivitiesSearchCriteria;
}

type LocalStateContext = StateContext<ActivitiesSearchStateModel>;

@State<ActivitiesSearchStateModel>({
  name: 'activitiesSearch',
  defaults: {
    facets: {
      peopleAndCompanies: [],
      types: [],
      subtypes: [],
    },
    criteria: new ActivitiesSearchCriteria(),
    items: {},
    itemIds: [],
  },
})
@Injectable()
export class ActivitiesSearchState {
  constructor(
    private _service: ActivitiesApiService,
    private _autocompleteService: AutocompleteService
  ) {}

  @Action(ActivitiesSearchActions.SearchReset)
  private _searchReset(
    ctx: LocalStateContext,
    { projectId, projectStatuses }: ActivitiesSearchActions.SearchReset
  ) {
    ctx.dispatch(
      new ActivitiesSearchActions.Search(
        projectId,
        new ActivitiesSearchCriteria(),
        projectStatuses
      )
    );
  }
  @Action(ActivitiesSearchActions.Search)
  private _search(
    ctx: LocalStateContext,
    { criteria, projectId, projectStatuses }: ActivitiesSearchActions.Search
  ) {
    const periods = getActivityPeriods(criteria, projectStatuses);

    ctx.patchState({
      criteria: { ...criteria, from: 1 },
      items: {},
      itemIds: [],
    });

    if (!periods.length) {
      return of(null).pipe(syncLoadProgress(ctx));
    }

    return combineLatest(
      periods.map((period, index) =>
        (period
          ? this._service.searchActivities(
              {
                ...criteria,
                from: 1,
                filters: {
                  ...criteria.filters,
                  ActivityDate: {
                    terms: [period.from, period.to],
                  },
                },
              },
              projectId
            )
          : of<SearchResult<Activity>>({ Results: [], ResultsTotal: 0 })
        ).pipe(
          tap(({ Results, ResultsTotal }) =>
            ctx.setState(
              setEntityStateItem(index, {
                currentPage: 1,
                activities: Results,
                total: ResultsTotal,
              })
            )
          )
        )
      )
    ).pipe(syncLoadProgress(ctx));
  }

  @Action(ActivitiesSearchActions.Search)
  private _getAggregations(
    ctx: LocalStateContext,
    { criteria, projectId }: ActivitiesSearchActions.Search
  ) {
    criteria = { ...criteria, size: 0, AggregationsOnly: true };

    return this._service.searchActivities(criteria, projectId).pipe(
      map((response) => {
        ctx.patchState({
          facets: {
            ...ctx.getState().facets,
            types: response.Aggregations.Types,
            subtypes: response.Aggregations.SubTypes,
          },
        });
        const companies = getCompaniesIds(response.Aggregations.Links);

        return { people: response.Aggregations.UserIds, companies };
      }),
      switchMap((peopleAndCompanies) =>
        combineLatest([
          peopleAndCompanies.people.length
            ? this._autocompleteService.usersAndTeams(
                getListAutocompleteParams(peopleAndCompanies.people)
              )
            : of([]),
          peopleAndCompanies.companies.length
            ? this._autocompleteService.parties(
                getListAutocompleteParams(peopleAndCompanies.companies)
              )
            : of([]),
        ])
      ),
      tap(([people, companies]) => {
        ctx.patchState({
          facets: {
            ...ctx.getState().facets,
            peopleAndCompanies: [
              ...mapPeopleToSimpleParties(people),
              ...mapCompaniesToSimpleParties(companies),
            ],
          },
        });
      })
    );
  }

  @Action(ActivitiesSearchActions.LoadMore)
  private _loadMore(
    ctx: LocalStateContext,
    {
      projectId,
      projectStatuses,
      activityGroupIndex,
    }: ActivitiesSearchActions.LoadMore
  ) {
    const { criteria = new ActivitiesSearchCriteria(), items } = ctx.getState();
    const activityGroup = items[activityGroupIndex];

    const period = getActivityPeriods(criteria, projectStatuses)[
      activityGroupIndex
    ];

    const currentPage = activityGroup?.currentPage + 1;

    return this._service
      .searchActivities(
        {
          ...criteria,
          from: currentPage,
          filters: {
            ...criteria.filters,
            ActivityDate: {
              terms: [period.from, period.to],
            },
          },
        },
        projectId
      )
      .pipe(
        syncLoadProgress(ctx),
        tap((activities: SearchResult<Activity>) =>
          ctx.setState(
            setEntityStateItem(activityGroupIndex, {
              currentPage,
              activities: append(activities.Results ?? []),
              total: activities.ResultsTotal,
            })
          )
        )
      );
  }

  @Action(ActivitiesActions.Create)
  create(
    ctx: LocalStateContext,
    { message, projectId }: ActivitiesActions.Create
  ) {
    return this._service.logActivity(message, projectId).pipe(
      syncLoadProgress(ctx),
      tap((newActivity) => {
        const lastActivityGroup = ctx.getState().itemIds.at(-1) ?? 0;

        ctx.setState(
          setEntityStateItem(lastActivityGroup, {
            activities: insertItem(newActivity),
            total: (total = 0) => total++,
          })
        );

        ctx.dispatch([
          new ToastActions.Show(TOAST_MESSAGES.ACTIVITY_CREATED_SUCCESS()),
        ]);
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.ACTIVITY_CREATED_FAIL())
        );

        return EMPTY;
      })
    );
  }
}
