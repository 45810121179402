import type { ActivitiesSearchCriteria } from '@esp/activities/data-access-api';
import type { ProjectStatus } from '@esp/projects/types';

interface Period {
  from: string;
  to: string;
}

export const getActivityPeriods = (
  criteria?: ActivitiesSearchCriteria,
  projectStatuses?: ProjectStatus[]
) => {
  let periods: Period[] = [];

  const activityDateFilter =
    criteria?.filters && criteria.filters['ActivityDate']?.terms;

  if (projectStatuses?.length) {
    const firstStep = Math.min(...projectStatuses.map((status) => status.Step));
    if (firstStep !== 0) {
      periods = Array(firstStep).fill(null);
    }
  }
  //get periods based on project statuses & activity date filter
  projectStatuses?.forEach((status, index) => {
    const period: Period = {
      from: new Date().toISOString(),
      to: new Date().toISOString(),
    };

    //set period 'from date' to project status create date
    //or to activity date filter start if is set and greater than create date
    period.from =
      activityDateFilter?.[0] &&
      new Date(activityDateFilter[0]) > new Date(status.CreateDate)
        ? activityDateFilter[0].toString()
        : status.CreateDate;

    //set period 'to date' to next project status create date or if doen't exist to today's date
    period.to = projectStatuses?.[index + 1]
      ? projectStatuses[index + 1].CreateDate
      : new Date().toISOString();

    //if activity filter end is set and is less than 'period to' overwite 'period to'
    period.to =
      activityDateFilter?.[1] &&
      new Date(activityDateFilter[1]) < new Date(period.to)
        ? activityDateFilter[1].toString()
        : period.to;

    periods.push(period);
  });

  // if project was reopened, combine all periods after first close in one
  if (periods.length > 3) {
    periods[2].to = periods[periods.length - 1].to;
    periods.splice(3, periods.length - 3);
  }

  return periods;
};
