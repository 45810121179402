import type {
  AutocompleteParams,
  SimpleParty,
  UserTeam,
} from '@esp/autocomplete/types-autocomplete';

export const mapPeopleToSimpleParties = (
  parties: UserTeam[]
): Partial<SimpleParty>[] => {
  return parties.map((party) => ({
    Id: party.Id,
    Name: party.Name,
    IsCompany: false,
  }));
};

export const mapCompaniesToSimpleParties = (
  parties: SimpleParty[]
): Partial<SimpleParty>[] => {
  return parties.map((party) => ({
    Id: party.Id,
    Name: party.Name,
    IsCompany: true,
  }));
};

export const getCompaniesIds = (links: string[]): number[] => {
  return links
    .filter((link: string) => link.split(':')?.[0] === 'Company')
    .map((link: string) => Number(link.split(':')[1]));
};

export const getListAutocompleteParams = (
  list: number[]
): AutocompleteParams => {
  return {
    term: '',
    size: list.length,
    filters: {
      Ids: {
        Terms: list,
        Behavior: 'Any',
      },
    },
  };
};
